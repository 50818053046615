import MDButton from "components/MDButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAppServices } from "hook/services";
import { useParams } from "react-router-dom";
const PlansModal = (props) => {
  const Service = useAppServices();
  const { location_id, app_id } = useParams()
  const [openAddProduct, setOpenAddProduct] = useState(false);
  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      setProcessing(true);
      const payload = {
        name: e.target.name.value,
        plan_id: e.target.plan_id.value,
        price: e.target.price.value,
        app_id: app_id,
        location_id: location_id
      };
      const { response } = await Service.snapshot_onboarding_plans.create({ payload });
      if (response) {
        setOpenAddProduct(false);
        setProcessing(false);
        props.handleChange();
      } else {
        setProcessing(false);
      }
    };
    return (
      <MDModal open={open} onClose={onClose}>
        <MDBox>
          <MDTypography component="h1" mb={3} variant="h5">
            Plan
          </MDTypography>

          <MDBox
            component="form"
            onSubmit={handleSubmit}
            role="form"
            sx={{ width: 335 }}
          >
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Plan Name
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="Plan Name"
                  name="name"
                />
              </MDBox>
            </Grid>
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Plan ID
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="Plan ID"
                  name="plan_id"
                />
              </MDBox>
            </Grid>
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Plan Price
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="Plan Price"
                  name="price"
                />
              </MDBox>
            </Grid>
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                disabled={processing}
                loading={processing}
                sx={{ mt: 4, mb: 1 }}
                fullWidth
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        sx={{ marginLeft: 3 }}
        variant="outlined"
        color="info"
        startIcon={<AddBoxIcon />}
        onClick={() => setOpenAddProduct(true)}
      >
        Plan
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default PlansModal;
